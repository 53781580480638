
import { $gettext } from 'vue-gettext';
import { App, useAppStore } from '@/stores/app';
import { BestinformedVersion, Product, useVersionStore } from '@/stores/version';
import { Breakpoint, useMedia } from '@/utils/responsive-breakpoints';
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import { currentLanguage, currentLanguageISOString, getTranslated } from '@/translation';
import { Customer, useCustomerStore } from '@/stores/customer';
import { Edition, useEditionStore } from '@/stores/edition';
import { json } from '@sahnee/ajax';
import { localizeDate } from '@/utils/date';
import { Tab } from '../card/BpCard';
import { Tooltip } from '@/utils/tooltip';
import { useUser } from '@/utils/user';
import BpArrowCalculationVue from '../arrow-calculation/BpArrowCalculation.vue';
import BpLicenseExtensionEditorAppListVue from './BpLicenseExtensionEditorAppList.vue';
import BpToast from '../toast/BpToasts';
import clone from '@sahnee/clone';
import router from '@/router';

export default defineComponent({
  name: 'bp-license-extension-editor',
  components: {
    BpArrowCalculation: BpArrowCalculationVue,
    BpLicenseExtensionEditorAppList: BpLicenseExtensionEditorAppListVue
  },
  props: {
    modelValue: {
      type: Object as PropType<Customer>,
      required: true
    }
  },
  setup(props) {
    ///-------------------------------------------------------------------
    /// PREVIEW
    ///-------------------------------------------------------------------

    const preview = ref({
      window: false,
      bestinformed: () => customerStore.hasProduct(props.modelValue, 'bestinformed') &&
      ( props.modelValue.products.bestinformed.version !== license.value.products.bestinformed.version ||
        edition.value !== licenseEdition.value ||
        (props.modelValue.numberOfLicences ?? 0) !== (license.value.numberOfLicences ?? 0) ||
        (props.modelValue.mobilecals ?? 0) !== (license.value.mobilecals ?? 0) ||
        (props.modelValue.clusternodes ?? 0) !== (license.value.clusternodes ?? 0) ||
        addedApps.value.length > 0 ||
        removedApps.value.length > 0 ),
      bestproxy: () => customerStore.hasProduct(props.modelValue, 'bestproxy') &&
      ( (props.modelValue.bestproxycores ?? 0) !== (license.value.bestproxycores ?? 0) ||
        (props.modelValue.bestproxyram ?? 0) !== (license.value.bestproxyram ?? 0) ||
        props.modelValue.webaccess !== license.value.webaccess ||
        (props.modelValue.bestproxywebaccess ?? 0) !== (license.value.bestproxywebaccess ?? 0) ),
      bestzero: () => customerStore.hasProduct(props.modelValue, 'bestzero') &&
      ( (props.modelValue.numberOfLicences ?? 0) !== (license.value.numberOfLicences ?? 0) ),
    });

    ///-------------------------------------------------------------------
    /// STORE
    ///-------------------------------------------------------------------

    const versionStore = useVersionStore();
    const customerStore = useCustomerStore();
    const editionStore = useEditionStore(true);
    const appStore = useAppStore();

    ///-------------------------------------------------------------------
    /// TABS
    ///-------------------------------------------------------------------

    const tabs = ref<Tab[]>([]);

    watch(() => [versionStore.isLoading(), props.modelValue, currentLanguage.value], () => {
      if (!versionStore.loaded || versionStore.isLoading()) { return }

      const found = [];
      for (const product of versionStore.getRootProducts()) {
        if (customerStore.hasProduct(props.modelValue, product.uuid)) {
          found.push(product);
        }
      }

      tabs.value = found.map((product) => {
        return { _id: product.uuid, name: versionStore.getName(product), icon: versionStore.getIcon(product) };
      })
    }, { immediate: true })

    ///-------------------------------------------------------------------
    /// LICENSE
    ///-------------------------------------------------------------------

    const license = ref<Customer>(clone(props.modelValue));

    ///-------------------------------------------------------------------
    /// EDITION
    ///-------------------------------------------------------------------

    const edition = ref('');
    const editionName = ref('');
    const licenseEdition = ref('');

    const bestinformed5Editions = ref<Edition[]>([]);
    const bestinformed6Editions = ref<Edition[]>([]);

    watch(() => [editionStore.isLoading(), customerStore.isLoading(), props.modelValue], () => {
      if (!editionStore.loaded || editionStore.isLoading() || customerStore.isLoading()) return;
      edition.value = customerStore.getBestinformedEdition(props.modelValue);
      editionName.value = customerStore.getBestinformedEditionName(props.modelValue);
      licenseEdition.value = customerStore.getBestinformedEdition(license.value);
    }, { immediate: true, deep: true });

    watch(() => [editionStore.isLoading(), customerStore.isLoading(), props.modelValue, currentLanguage.value], () => {
      if (!editionStore.loaded || editionStore.isLoading() || customerStore.isLoading()) return;

      const editionId = customerStore.getVTigerBestinformedEdition(props.modelValue);
      const vTigerBestinformedEdition = editionStore.getById(editionId);
      if (!vTigerBestinformedEdition) return;

      const bestinformed5PossibleUpgrades = Object.keys(vTigerBestinformedEdition.upgrade['5']);
      bestinformed5Editions.value = editionStore.editions.bestinformed5
        .map(edition => {
          return {
            ...edition,
            _disabled: !props.modelValue.subscription && !bestinformed5PossibleUpgrades.includes(edition._id) && edition._id !== editionId
          }
        });
        
      const bestinformed6PossibleUpgrades = Object.keys(vTigerBestinformedEdition.upgrade['6']);
      bestinformed6Editions.value = editionStore.editions.bestinformed6
        .map(edition => {
          return {
            ...edition,
            _disabled: !props.modelValue.subscription && !bestinformed6PossibleUpgrades.includes(edition._id) && edition._id !== editionId
          }
        })
    }, { immediate: true })

    function updateEdition(id: string) {
      licenseEdition.value = id;
      if (productVersion.value === getProductVersion('5')) {
        license.value.editionProduct = id;
      } else if (productVersion.value === getProductVersion('6')) {
        license.value.editionApps = id;

        const edition = editionStore.getById(id);
        if (!edition) return;

        const apps = edition.apps.filter(app => app !== id) ?? [];
        const excludedApps = edition.excludedApps.filter(app => app !== id) ?? [];
        const migratedApps = customerStore.getBestinformedApps(props.modelValue);
        const allDependentApps = (appId: string): App[] => dependentApps.value.get(appId)?.flatMap(app => [app, ...allDependentApps(app._id)]) || [];

        for (const appId of apps) {
          const appIndex = license.value.apps.findIndex(app => app === appId);
          if (appIndex === -1) {
            license.value.apps.push(appId);
          }
        }
        
        for (const excludedAppId of excludedApps) {
          const excludedAppIndex = license.value.apps.findIndex(app => app === excludedAppId);
          if (excludedAppIndex !== -1) {
            license.value.apps.splice(excludedAppIndex, 1);
            for (const dependentApp of allDependentApps(excludedAppId)) {
              const dependentAppIndex = license.value.apps.findIndex((appId: string) => appId === dependentApp._id);
              if (dependentAppIndex !== -1) {
                license.value.apps.splice(dependentAppIndex, 1);
              }
            }
          }
        }

        const appsToRemove = license.value.apps.filter(app => !migratedApps.includes(app) && !apps.includes(app))
        for (const appToRemoveId of appsToRemove) {
          const appToRemoveIndex = license.value.apps.findIndex(app => app === appToRemoveId);
          if (appToRemoveIndex !== -1) {
            license.value.apps.splice(appToRemoveIndex, 1);
            for (const dependentApp of allDependentApps(appToRemoveId)) {
              const dependentAppIndex = license.value.apps.findIndex((appId: string) => appId === dependentApp._id);
              if (dependentAppIndex !== -1) {
                license.value.apps.splice(dependentAppIndex, 1);
              }
            }
          }
        }
      }
    }

    ///-------------------------------------------------------------------
    /// VERSIONS
    ///-------------------------------------------------------------------

    // PRODUCT VERSIONS
    const productVersions = ref<Product[]>([]);
    const productVersion = ref(props.modelValue.editionProduct !== undefined && props.modelValue.editionProduct !== 'Apps' ? getProductVersion('5') : getProductVersion('6'));
    const currentProductVersion = ref<Product>();
    // const productVersionTransform = (product: Product) => {
    //   const name = product.name[currentLanguageISOString()];
    //   if (!name) return '';
    //   if (name.toLowerCase().includes('version 5')) return `${name} <em style="opacity: 0.5;">(${$gettext('Technical support ended on %{date}', { date: localizeDate(new Date('2019-12-31')) })})</em>`;
    //   return name;
    // }

    watch(() => [versionStore.isLoading(), props.modelValue], async () => {
      if (!versionStore.loaded || versionStore.isLoading()) { return }
      const productId = versionStore.findVersionByName('bestinformed')?._id ?? 'root';
      productVersions.value = versionStore.getFlatSubtree(productId)
        .filter(product => product.doc_type === 'version_product' && product._id !== productId)
        .map(product => {
          delete product._children;
          if ((product as Product).uuid !== 'version_5') {
            (product as Product).name = Object.fromEntries(
              Object.entries((product as Product).name).map(([language, name]) => [language, $gettext('Upgrade to %{version}', { version: name })])
            )
          }
          return product as Product
        })
      const version = await customerStore.getBestinformedVersion(props.modelValue);
      if (!version) return;
      productVersion.value = version._id || 'root';
      currentProductVersion.value = version;
    }, { immediate: true })

    watch(() => [productVersion.value, editionStore.isLoading(), customerStore.isLoading()], () => {
      if (!editionStore.loaded || editionStore.isLoading() || customerStore.isLoading()) return;
      if (productVersion.value === getProductVersion('6')) {
        // VERSION
        license.value.products.bestinformed.version = '6';
        license.value.editionProduct = 'Apps';
        // EDITION
        const edition = editionStore.getById(customerStore.getBestinformedEdition(props.modelValue));
        if (edition) {
          license.value.editionApps = props.modelValue.products.bestinformed.version === '5' ? Object.keys(edition.migrate['6'])[0] : edition._id;
        }
        // APPS
        license.value.apps = [...customerStore.getBestinformedApps(props.modelValue)];
      } else {
        // VERSION
        license.value.products.bestinformed.version = '5';
        license.value.editionProduct = customerStore.getBestinformedEdition(props.modelValue);
        // EDITION
        license.value.editionApps = '';
        // APPS
        license.value.apps = [];
        // LICENSE TYPE
        license.value.subscription = false;
        // CALS
        license.value.mobilecals = 0;
        license.value.clusternodes = 0;
        // SERVICES
        license.value.hosting = false;
      }
    })

    function getProductVersion(v: BestinformedVersion) {
      return versionStore.findVersionByName(v, productVersions.value)?._id || 'root'
    }

    const productVersionName = computed(() => {
      if (!versionStore.loaded || versionStore.isLoading()) {
        return '';
      }
      return versionStore.getNameById(productVersion.value)
    })

    ///-------------------------------------------------------------------
    /// APPS
    ///-------------------------------------------------------------------

    // APPS
    const editionApps = (basis: Customer) => (editionStore.getById(customerStore.getBestinformedEdition(basis))?.apps || []).filter(app => !['starter', 'standard', 'alarm'].includes(app));
    const customerApps = (basis: Customer) => customerStore.getBestinformedApps(basis).filter(app => !['starter', 'standard', 'alarm'].includes(app));

    // EXCLUDED APPS
    const editionExcludedApps = (basis: Customer) => (editionStore.getById(customerStore.getBestinformedEdition(basis))?.excludedApps || []).filter(app => !['starter', 'standard', 'alarm'].includes(app));
    const customerExcludedApps = (basis: Customer) => customerStore.getBestinformedExcludedApps(basis).filter(app => !['starter', 'standard', 'alarm'].includes(app));

    // DEPENDENT APPS
    const dependentApps = computed<Map<App['_id'], App[]>>(() => new Map(appStore.getAll().map(app => [app._id, appStore.getAll().filter(a => a.dependencies.includes(app._id))])));

    // APP TYPE TOOLTIP
    function appTooltip(basis: Customer): Tooltip {
      const tooltips: string[] = [];
      const apps = customerApps(basis);
      const excludedApps = customerExcludedApps(basis);
      if (apps.length > 0 && apps.map(app => editionApps(basis).includes(app)).includes(false)) {
        tooltips.push(`<p><span class="bp-license-extension-editor__app--active">${$gettext('Bold')}</span>&ensp;–&ensp;${$gettext('The app is licensed and it is not included in the %{edition}', { edition: editionName.value })}</p>`);
      }
      if (editionApps(basis).length > 0) {
        tooltips.push(`<p><span class="bp-license-extension-editor__app--included">${$gettext('Italic')}</span>&ensp;–&ensp;${$gettext('The app is included in the %{edition}', { edition: editionName.value })}</p>`)
      }
      if ([...editionExcludedApps(basis), ...excludedApps].length > 0) {
        tooltips.push(`<p><span class="bp-license-extension-editor__app--excluded">${$gettext('Strikethrough')}</span>&ensp;–&ensp;${$gettext('The app is excluded from the %{edition}', { edition: editionName.value })}</p>`)
      }
      return tooltips.join('');
    }

    // ADDED & REMOVED APPS TRACKING
    const addedApps = ref<App[]>([]);
    const removedApps = ref<App[]>([]);

    watch(() => [appStore.isLoading(), customerStore.isLoading(), props.modelValue.apps, license.value.apps], () => {
      if (!appStore.loaded || appStore.isLoading() || customerStore.isLoading()) return;
      const oldApps = license.value.products.bestinformed.version === '5' ? [] : customerStore.getBestinformedApps(props.modelValue);
      const newApps = license.value.apps ?? [];
      console.log('apps', { new: newApps, old: oldApps })
      const added = appStore.getById(newApps.filter(app => !oldApps.includes(app)));
      addedApps.value = Array.isArray(added) ? added : [added];
      const removed = appStore.getById(oldApps.filter(app => !newApps.includes(app)));
      removedApps.value = Array.isArray(removed) ? removed : [removed];
    }, { deep: true })

    ///-------------------------------------------------------------------
    /// ARROW
    ///-------------------------------------------------------------------

    const isDesktop = useMedia(Breakpoint.MIN_MD);
    const arrow = computed(() => isDesktop.value ? 'arrow-right-long' : 'arrow-down');

    ///-------------------------------------------------------------------
    /// SEND REQUEST
    ///-------------------------------------------------------------------

    const user = useUser();

    async function sendRequest() {
      const response = await json<{ success: boolean }>('/api/license', {
        method: 'POST',
        json: {
          action: 'extension-inquiry',
          email: user.value.email,
          license: license.value,
          products: [preview.value.bestinformed() && 'bestinformed', preview.value.bestproxy() && 'bestproxy', preview.value.bestzero() && 'bestzero'].filter(product => product),
        }
      });
      if (response.success) {
        BpToast.show({
          color: 'green',
          title: $gettext('License extension request success'),
          content: $gettext('Requesting license extension was successfull. An employee will contact you shortly.'),
          icon: 'circle-check',
        });
        router.push({ name: 'home' });
      } else {
        BpToast.show({
          color: 'red',
          title: $gettext('License extension request failed'),
          content: $gettext('Requesting license extension failed.'),
          icon: 'triangle-exclamation',
        });
      }
    }

    return {
      addedApps,
      appStore,
      appTooltip,
      arrow,
      bestinformed5Editions,
      bestinformed6Editions,
      currentLanguageISOString,
      currentProductVersion,
      customerStore,
      edition,
      editionName,
      editionStore,
      getProductVersion,
      getTranslated,
      isDesktop,
      license,
      licenseEdition,
      localizeDate,
      preview,
      productVersion,
      productVersionName,
      productVersions,
      // productVersionTransform,
      removedApps,
      sendRequest,
      tabs,
      updateEdition,
      user,
      versionStore,
    }
  }
})

