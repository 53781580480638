import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex-column gap-md" }
const _hoisted_2 = { class: "bp-license-extension-editor__wrapper" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "bp-license-extension-editor__wrapper" }
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { key: 4 }
const _hoisted_9 = { class: "bp-license-extension-editor__wrapper" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { key: 4 }
const _hoisted_13 = { class: "bp-license-extension-editor__wrapper" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { class: "bp-license-extension-editor__wrapper" }
const _hoisted_16 = { class: "flex-column gap-md" }
const _hoisted_17 = { class: "bp-license-extension-editor__wrapper" }
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { class: "bp-license-extension-editor__wrapper" }
const _hoisted_20 = { key: 0 }
const _hoisted_21 = { class: "bp-license-extension-editor__list" }
const _hoisted_22 = { class: "bp-license-extension-editor__wrapper" }
const _hoisted_23 = { class: "flex gap-sm" }
const _hoisted_24 = { key: 0 }
const _hoisted_25 = { class: "bp-license-extension-editor__wrapper" }
const _hoisted_26 = { class: "flex-column gap-md" }
const _hoisted_27 = { class: "bp-license-extension-editor__wrapper" }
const _hoisted_28 = { class: "flex-column gap-md" }
const _hoisted_29 = { class: "mt-md" }
const _hoisted_30 = { class: "bp-license-extension-editor__list" }
const _hoisted_31 = { class: "bp-license-extension-editor__list" }
const _hoisted_32 = { class: "bp-license-extension-editor__list" }
const _hoisted_33 = { class: "flex gap-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _component_bp_select = _resolveComponent("bp-select")!
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_label = _resolveComponent("bp-label")!
  const _component_bp_license_extension_editor_app_list = _resolveComponent("bp-license-extension-editor-app-list")!
  const _component_bp_input = _resolveComponent("bp-input")!
  const _component_bp_tooltip = _resolveComponent("bp-tooltip")!
  const _component_bp_form_button = _resolveComponent("bp-form-button")!
  const _component_bp_card = _resolveComponent("bp-card")!
  const _component_bp_arrow_calculation = _resolveComponent("bp-arrow-calculation")!
  const _component_bp_fieldset = _resolveComponent("bp-fieldset")!
  const _component_bp_window = _resolveComponent("bp-window")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_bp_card, {
      "fill-height": "",
      "fill-width": "",
      tabs: _ctx.tabs,
      "tabs-position": "inside"
    }, {
      "tab-content-bestinformed": _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (_ctx.currentProductVersion?._id === _ctx.getProductVersion('5'))
            ? (_openBlock(), _createBlock(_component_bp_label, {
                key: 0,
                "label-position": "top",
                label: _ctx.$gettext('Version')
              }, {
                text: _withCtx(() => [
                  _createElementVNode("em", null, [
                    _cache[13] || (_cache[13] = _createElementVNode("sup", null, "[1]", -1)),
                    _cache[14] || (_cache[14] = _createTextVNode()),
                    _createVNode(_component_translate, {
                      "translate-params": { date: _ctx.localizeDate(new Date('2019-12-31')) }
                    }, {
                      default: _withCtx(() => _cache[12] || (_cache[12] = [
                        _createTextVNode("Technichal support ended on %{date}")
                      ])),
                      _: 1
                    }, 8, ["translate-params"])
                  ])
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("span", {
                      innerHTML: _ctx.versionStore.getName(_ctx.currentProductVersion) + ' <em><sup>[1]</sup></em>'
                    }, null, 8, _hoisted_3),
                    _createVNode(_component_bp_icon, { icon: _ctx.arrow }, null, 8, ["icon"]),
                    _createVNode(_component_bp_select, {
                      data: _ctx.productVersions,
                      "search-property": `name.${_ctx.currentLanguageISOString()}`,
                      modelValue: _ctx.productVersion,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.productVersion) = $event))
                    }, null, 8, ["data", "search-property", "modelValue"]),
                    (!_ctx.isDesktop)
                      ? (_openBlock(), _createElementBlock("hr", _hoisted_4))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              }, 8, ["label"]))
            : _createCommentVNode("", true),
          ((_ctx.currentProductVersion?._id === _ctx.getProductVersion('5') && _ctx.license.products.bestinformed.version !== '5') || _ctx.currentProductVersion?._id !== _ctx.getProductVersion('5'))
            ? (_openBlock(), _createBlock(_component_bp_label, {
                key: 1,
                "label-position": "top",
                label: _ctx.$gettext('Edition')
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.editionName), 1),
                    _createVNode(_component_bp_icon, { icon: _ctx.arrow }, null, 8, ["icon"]),
                    (_ctx.editionStore.loaded && _ctx.productVersion === _ctx.getProductVersion('5'))
                      ? (_openBlock(), _createBlock(_component_bp_select, {
                          key: 0,
                          data: _ctx.bestinformed5Editions,
                          "search-property": "name",
                          "model-value": _ctx.license.editionProduct || _ctx.edition,
                          "onUpdate:modelValue": _ctx.updateEdition
                        }, null, 8, ["data", "model-value", "onUpdate:modelValue"]))
                      : (_ctx.editionStore.loaded && _ctx.productVersion === _ctx.getProductVersion('6'))
                        ? (_openBlock(), _createBlock(_component_bp_select, {
                            key: 1,
                            data: _ctx.bestinformed6Editions,
                            "search-property": "name",
                            "model-value": _ctx.license.editionApps || _ctx.edition,
                            "onUpdate:modelValue": _ctx.updateEdition
                          }, null, 8, ["data", "model-value", "onUpdate:modelValue"]))
                        : _createCommentVNode("", true),
                    (!_ctx.isDesktop)
                      ? (_openBlock(), _createElementBlock("hr", _hoisted_6))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              }, 8, ["label"]))
            : _createCommentVNode("", true),
          (_ctx.productVersion === _ctx.getProductVersion('6'))
            ? (_openBlock(), _createElementBlock("hr", _hoisted_7))
            : _createCommentVNode("", true),
          (_ctx.productVersion === _ctx.getProductVersion('6'))
            ? (_openBlock(), _createBlock(_component_bp_label, {
                key: 3,
                "label-position": "top"
              }, {
                label: _withCtx(() => [
                  _createVNode(_component_translate, null, {
                    default: _withCtx(() => _cache[15] || (_cache[15] = [
                      _createTextVNode("App Plus")
                    ])),
                    _: 1
                  }),
                  _withDirectives((_openBlock(), _createBlock(_component_bp_icon, {
                    class: "ml-sm",
                    icon: ['fad', 'info-circle'],
                    key: _ctx.modelValue._id
                  })), [
                    [_directive_tooltip, _ctx.appTooltip(_ctx.modelValue)]
                  ])
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_bp_license_extension_editor_app_list, {
                    apps: _ctx.appStore.apps.appPlus,
                    customer: _ctx.modelValue,
                    license: _ctx.license,
                    "onUpdate:license": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.license) = $event))
                  }, null, 8, ["apps", "customer", "license"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.productVersion === _ctx.getProductVersion('6'))
            ? (_openBlock(), _createElementBlock("hr", _hoisted_8))
            : _createCommentVNode("", true),
          (_ctx.productVersion === _ctx.getProductVersion('6'))
            ? (_openBlock(), _createBlock(_component_bp_label, {
                key: 5,
                "label-position": "top"
              }, {
                label: _withCtx(() => [
                  _createVNode(_component_translate, null, {
                    default: _withCtx(() => _cache[16] || (_cache[16] = [
                      _createTextVNode("Additional apps")
                    ])),
                    _: 1
                  }),
                  _withDirectives((_openBlock(), _createBlock(_component_bp_icon, {
                    class: "ml-sm",
                    icon: ['fad', 'info-circle'],
                    key: _ctx.modelValue._id
                  })), [
                    [_directive_tooltip, _ctx.appTooltip(_ctx.modelValue)]
                  ])
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_bp_license_extension_editor_app_list, {
                    apps: _ctx.appStore.apps.additionalApps,
                    customer: _ctx.modelValue,
                    license: _ctx.license,
                    "onUpdate:license": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.license) = $event))
                  }, null, 8, ["apps", "customer", "license"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _cache[17] || (_cache[17] = _createElementVNode("hr", null, null, -1)),
          _createVNode(_component_bp_label, {
            label: _ctx.$gettext('CALs'),
            "label-position": "top"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_9, [
                (_ctx.modelValue.unlimited_cals)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
                      _createVNode(_component_bp_icon, { icon: "infinity" })
                    ]))
                  : (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.modelValue.numberOfLicences || 0), 1)),
                (!_ctx.modelValue.unlimited_cals)
                  ? (_openBlock(), _createBlock(_component_bp_icon, {
                      key: 2,
                      icon: _ctx.arrow
                    }, null, 8, ["icon"]))
                  : _createCommentVNode("", true),
                (!_ctx.modelValue.unlimited_cals)
                  ? (_openBlock(), _createBlock(_component_bp_input, {
                      key: 3,
                      type: "number",
                      min: 1,
                      modelValue: _ctx.license.numberOfLicences,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.license.numberOfLicences) = $event))
                    }, null, 8, ["modelValue"]))
                  : _createCommentVNode("", true),
                (!_ctx.isDesktop)
                  ? (_openBlock(), _createElementBlock("hr", _hoisted_12))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }, 8, ["label"]),
          (_ctx.productVersion === _ctx.getProductVersion('6'))
            ? (_openBlock(), _createBlock(_component_bp_label, {
                key: 6,
                label: _ctx.$gettext('Mobile CALs'),
                "label-position": "top"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.modelValue.mobilecals || 0), 1),
                    _createVNode(_component_bp_icon, { icon: _ctx.arrow }, null, 8, ["icon"]),
                    _createVNode(_component_bp_tooltip, {
                      condition: !_ctx.license.apps.includes('mobile'),
                      tooltip: _ctx.$gettext('Additional app %{app} is required', { app: _ctx.appStore.getById('mobile')?.name || '' })
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_bp_input, {
                          type: "number",
                          min: 0,
                          modelValue: _ctx.license.mobilecals,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.license.mobilecals) = $event)),
                          disabled: !_ctx.license.apps.includes('mobile')
                        }, null, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["condition", "tooltip"]),
                    (!_ctx.isDesktop)
                      ? (_openBlock(), _createElementBlock("hr", _hoisted_14))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              }, 8, ["label"]))
            : _createCommentVNode("", true),
          (_ctx.productVersion === _ctx.getProductVersion('6'))
            ? (_openBlock(), _createBlock(_component_bp_label, {
                key: 7,
                label: _ctx.$gettext('Cluster nodes'),
                "label-position": "top"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_15, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.modelValue.clusternodes || 0), 1),
                    _createVNode(_component_bp_icon, { icon: _ctx.arrow }, null, 8, ["icon"]),
                    _createVNode(_component_bp_input, {
                      type: "number",
                      min: 0,
                      modelValue: _ctx.license.clusternodes,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.license.clusternodes) = $event))
                    }, null, 8, ["modelValue"])
                  ])
                ]),
                _: 1
              }, 8, ["label"]))
            : _createCommentVNode("", true)
        ])
      ]),
      "tab-content-bestproxy": _withCtx(() => [
        _createElementVNode("div", _hoisted_16, [
          _createVNode(_component_bp_label, {
            label: _ctx.$gettext('CPU Cores'),
            "label-position": "top"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("span", null, _toDisplayString(_ctx.modelValue.bestproxycores || 0), 1),
                _createVNode(_component_bp_icon, { icon: _ctx.arrow }, null, 8, ["icon"]),
                _createVNode(_component_bp_input, {
                  type: "number",
                  min: 1,
                  modelValue: _ctx.license.bestproxycores,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.license.bestproxycores) = $event))
                }, null, 8, ["modelValue"]),
                (!_ctx.isDesktop)
                  ? (_openBlock(), _createElementBlock("hr", _hoisted_18))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_bp_label, {
            label: _ctx.$gettext('RAM in GB'),
            "label-position": "top"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_19, [
                _createElementVNode("span", null, _toDisplayString(_ctx.modelValue.bestproxyram || 0), 1),
                _createVNode(_component_bp_icon, { icon: _ctx.arrow }, null, 8, ["icon"]),
                _createVNode(_component_bp_input, {
                  type: "number",
                  min: 1,
                  modelValue: _ctx.license.bestproxyram,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.license.bestproxyram) = $event))
                }, null, 8, ["modelValue"]),
                (!_ctx.isDesktop)
                  ? (_openBlock(), _createElementBlock("hr", _hoisted_20))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          }, 8, ["label"]),
          _cache[19] || (_cache[19] = _createElementVNode("hr", null, null, -1)),
          _createVNode(_component_bp_label, {
            label: _ctx.$gettext('Features'),
            "label-position": "top"
          }, {
            default: _withCtx(() => [
              _createElementVNode("ul", _hoisted_21, [
                _createElementVNode("li", null, [
                  _createElementVNode("div", _hoisted_22, [
                    _createElementVNode("div", _hoisted_23, [
                      _createVNode(_component_bp_icon, {
                        icon: _ctx.modelValue.webaccess ? 'check' : 'xmark',
                        color: _ctx.modelValue.webaccess ? 'green' : 'red'
                      }, null, 8, ["icon", "color"]),
                      _createVNode(_component_translate, null, {
                        default: _withCtx(() => _cache[18] || (_cache[18] = [
                          _createTextVNode("Access to the web interface")
                        ])),
                        _: 1
                      })
                    ]),
                    _createVNode(_component_bp_icon, { icon: _ctx.arrow }, null, 8, ["icon"]),
                    _createVNode(_component_bp_input, {
                      seamless: "",
                      flush: "",
                      type: "checkbox",
                      modelValue: _ctx.license.webaccess,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.license.webaccess) = $event)),
                      text: _ctx.$gettext('Access to the web interface')
                    }, null, 8, ["modelValue", "text"]),
                    (!_ctx.isDesktop)
                      ? (_openBlock(), _createElementBlock("hr", _hoisted_24))
                      : _createCommentVNode("", true)
                  ])
                ])
              ])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_bp_label, {
            label: _ctx.$gettext('Amount of concurrent web accesses'),
            "label-position": "top"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_25, [
                _createElementVNode("span", null, _toDisplayString(_ctx.modelValue.bestproxywebaccess || 0), 1),
                _createVNode(_component_bp_icon, { icon: _ctx.arrow }, null, 8, ["icon"]),
                _createVNode(_component_bp_tooltip, {
                  condition: !_ctx.license.webaccess,
                  tooltip: _ctx.$gettext('"Access to the web interface" is required')
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_bp_input, {
                      type: "number",
                      min: 0,
                      modelValue: _ctx.license.bestproxywebaccess,
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.license.bestproxywebaccess) = $event)),
                      disabled: !_ctx.license.webaccess
                    }, null, 8, ["modelValue", "disabled"])
                  ]),
                  _: 1
                }, 8, ["condition", "tooltip"])
              ])
            ]),
            _: 1
          }, 8, ["label"])
        ])
      ]),
      "tab-content-bestzero": _withCtx(() => [
        _createElementVNode("div", _hoisted_26, [
          _createVNode(_component_bp_label, {
            label: _ctx.$gettext('Connections'),
            "label-position": "top"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_27, [
                _createElementVNode("span", null, _toDisplayString(_ctx.modelValue.numberOfLicences || 0), 1),
                _createVNode(_component_bp_icon, { icon: _ctx.arrow }, null, 8, ["icon"]),
                _createVNode(_component_bp_input, {
                  type: "number",
                  modelValue: _ctx.license.numberOfLicences,
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.license.numberOfLicences) = $event))
                }, null, 8, ["modelValue"])
              ])
            ]),
            _: 1
          }, 8, ["label"])
        ])
      ]),
      "tabs-footer": _withCtx(() => [
        _createVNode(_component_bp_form_button, {
          disabled: !_ctx.preview.bestinformed() && !_ctx.preview.bestproxy() && !_ctx.preview.bestzero(),
          icon: ['far', 'magnifying-glass'],
          action: () => _ctx.preview.window = true
        }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[20] || (_cache[20] = [
                _createTextVNode("Preview")
              ])),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["disabled", "action"])
      ]),
      _: 1
    }, 8, ["tabs"]),
    _createVNode(_component_bp_window, {
      class: "bp-license-extension-editor__preview",
      modelValue: _ctx.preview.window,
      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.preview.window) = $event))
    }, {
      header: _withCtx(() => [
        _createVNode(_component_translate, { tag: "h2" }, {
          default: _withCtx(() => _cache[21] || (_cache[21] = [
            _createTextVNode("Preview")
          ])),
          _: 1
        })
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_bp_form_button, {
          tooltip: _ctx.user.roles.includes('impersonate_user') ? _ctx.$gettext('Not available as impersonated user') : undefined,
          disabled: _ctx.user.roles.includes('impersonate_user'),
          icon: ['far', 'envelope'],
          action: _ctx.sendRequest
        }, {
          default: _withCtx(() => [
            _createVNode(_component_translate, null, {
              default: _withCtx(() => _cache[28] || (_cache[28] = [
                _createTextVNode("Send request")
              ])),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["tooltip", "disabled", "action"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_28, [
          _createVNode(_component_translate, { tag: "div" }, {
            default: _withCtx(() => _cache[22] || (_cache[22] = [
              _createTextVNode("You want to change the following on your license:")
            ])),
            _: 1
          }),
          (_ctx.preview.bestinformed())
            ? (_openBlock(), _createBlock(_component_bp_fieldset, {
                key: 0,
                "intense-border": "",
                collapsible: ""
              }, {
                legend: _withCtx(() => _cache[23] || (_cache[23] = [
                  _createTextVNode("bestinformed")
                ])),
                default: _withCtx(() => [
                  (_ctx.modelValue.products.bestinformed.version === '5' && _ctx.license.products.bestinformed.version !== '5')
                    ? (_openBlock(), _createBlock(_component_bp_label, {
                        key: 0,
                        label: _ctx.$gettext('Version'),
                        "label-width": "38.2%"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_translate, {
                            class: "mt-md",
                            "translate-params": { version: _ctx.productVersionName }
                          }, {
                            default: _withCtx(() => _cache[24] || (_cache[24] = [
                              _createTextVNode("Upgrade to %{version}")
                            ])),
                            _: 1
                          }, 8, ["translate-params"])
                        ]),
                        _: 1
                      }, 8, ["label"]))
                    : _createCommentVNode("", true),
                  (_ctx.edition !== _ctx.licenseEdition)
                    ? (_openBlock(), _createBlock(_component_bp_label, {
                        key: 1,
                        label: _ctx.$gettext('New edition'),
                        "label-width": "38.2%"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_29, _toDisplayString(_ctx.customerStore.getBestinformedEditionName(_ctx.license)), 1)
                        ]),
                        _: 1
                      }, 8, ["label"]))
                    : _createCommentVNode("", true),
                  (_ctx.addedApps.length > 0)
                    ? (_openBlock(), _createBlock(_component_bp_label, {
                        key: 2,
                        label: _ctx.$gettext('Added apps'),
                        "label-width": "38.2%"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("ul", _hoisted_30, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.addedApps, (addedApp) => {
                              return (_openBlock(), _createElementBlock("li", {
                                key: addedApp._id,
                                class: "flex gap-sm"
                              }, [
                                _createVNode(_component_bp_icon, {
                                  icon: "plus",
                                  color: "green"
                                }),
                                _createElementVNode("span", null, _toDisplayString(addedApp.name), 1)
                              ]))
                            }), 128))
                          ])
                        ]),
                        _: 1
                      }, 8, ["label"]))
                    : _createCommentVNode("", true),
                  (_ctx.removedApps.length > 0)
                    ? (_openBlock(), _createBlock(_component_bp_label, {
                        key: 3,
                        label: _ctx.$gettext('Removed apps'),
                        "label-width": "38.2%"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("ul", _hoisted_31, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.removedApps, (removedApp) => {
                              return (_openBlock(), _createElementBlock("li", {
                                key: removedApp._id,
                                class: "flex gap-sm"
                              }, [
                                _createVNode(_component_bp_icon, {
                                  icon: "minus",
                                  color: "red"
                                }),
                                _createElementVNode("span", null, _toDisplayString(removedApp.name), 1)
                              ]))
                            }), 128))
                          ])
                        ]),
                        _: 1
                      }, 8, ["label"]))
                    : _createCommentVNode("", true),
                  ((_ctx.modelValue.numberOfLicences ?? 0) !== (_ctx.license.numberOfLicences ?? 0))
                    ? (_openBlock(), _createBlock(_component_bp_label, {
                        key: 4,
                        label: _ctx.$gettext('CALs'),
                        "label-width": "38.2%"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_bp_arrow_calculation, {
                            "old-value": (_ctx.modelValue.numberOfLicences ?? 0),
                            "new-value": (_ctx.license.numberOfLicences ?? 0)
                          }, null, 8, ["old-value", "new-value"])
                        ]),
                        _: 1
                      }, 8, ["label"]))
                    : _createCommentVNode("", true),
                  ((_ctx.modelValue.mobilecals ?? 0) !== (_ctx.license.mobilecals ?? 0))
                    ? (_openBlock(), _createBlock(_component_bp_label, {
                        key: 5,
                        label: _ctx.$gettext('Mobile CALs'),
                        "label-width": "38.2%"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_bp_arrow_calculation, {
                            "old-value": (_ctx.modelValue.mobilecals ?? 0),
                            "new-value": (_ctx.license.mobilecals ?? 0)
                          }, null, 8, ["old-value", "new-value"])
                        ]),
                        _: 1
                      }, 8, ["label"]))
                    : _createCommentVNode("", true),
                  ((_ctx.modelValue.clusternodes ?? 0) !== (_ctx.license.clusternodes ?? 0))
                    ? (_openBlock(), _createBlock(_component_bp_label, {
                        key: 6,
                        label: _ctx.$gettext('Cluster nodes'),
                        "label-width": "38.2%"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_bp_arrow_calculation, {
                            "old-value": (_ctx.modelValue.clusternodes ?? 0),
                            "new-value": (_ctx.license.clusternodes ?? 0)
                          }, null, 8, ["old-value", "new-value"])
                        ]),
                        _: 1
                      }, 8, ["label"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.preview.bestproxy())
            ? (_openBlock(), _createBlock(_component_bp_fieldset, {
                key: 1,
                "intense-border": "",
                collapsible: ""
              }, {
                legend: _withCtx(() => _cache[25] || (_cache[25] = [
                  _createTextVNode("bestproxy")
                ])),
                default: _withCtx(() => [
                  ((_ctx.modelValue.bestproxycores ?? 0) !== (_ctx.license.bestproxycores ?? 0))
                    ? (_openBlock(), _createBlock(_component_bp_label, {
                        key: 0,
                        label: _ctx.$gettext('CPU cores'),
                        "label-width": "38.2%"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_bp_arrow_calculation, {
                            "old-value": (_ctx.modelValue.bestproxycores ?? 0),
                            "new-value": (_ctx.license.bestproxycores ?? 0)
                          }, null, 8, ["old-value", "new-value"])
                        ]),
                        _: 1
                      }, 8, ["label"]))
                    : _createCommentVNode("", true),
                  ((_ctx.modelValue.bestproxyram ?? 0) !== (_ctx.license.bestproxyram ?? 0))
                    ? (_openBlock(), _createBlock(_component_bp_label, {
                        key: 1,
                        label: _ctx.$gettext('RAM in GB'),
                        "label-width": "38.2%"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_bp_arrow_calculation, {
                            "old-value": (_ctx.modelValue.bestproxyram ?? 0),
                            "new-value": (_ctx.license.bestproxyram ?? 0)
                          }, null, 8, ["old-value", "new-value"])
                        ]),
                        _: 1
                      }, 8, ["label"]))
                    : _createCommentVNode("", true),
                  (_ctx.modelValue.webaccess !== _ctx.license.webaccess)
                    ? (_openBlock(), _createBlock(_component_bp_label, {
                        key: 2,
                        label: _ctx.license.webaccess ? _ctx.$gettext('Added features') : _ctx.$gettext('Removed features'),
                        "label-width": "38.2%"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("ul", _hoisted_32, [
                            _createElementVNode("li", _hoisted_33, [
                              _createVNode(_component_bp_icon, {
                                icon: _ctx.license.webaccess ? 'plus' : 'minus',
                                color: _ctx.license.webaccess ? 'green' : 'red'
                              }, null, 8, ["icon", "color"]),
                              _createVNode(_component_translate, null, {
                                default: _withCtx(() => _cache[26] || (_cache[26] = [
                                  _createTextVNode("Access to the web interface")
                                ])),
                                _: 1
                              })
                            ])
                          ])
                        ]),
                        _: 1
                      }, 8, ["label"]))
                    : _createCommentVNode("", true),
                  ((_ctx.modelValue.bestproxywebaccess ?? 0) !== (_ctx.license.bestproxywebaccess ?? 0))
                    ? (_openBlock(), _createBlock(_component_bp_label, {
                        key: 3,
                        label: _ctx.$gettext('Webaccess count'),
                        "label-width": "38.2%"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_bp_arrow_calculation, {
                            "old-value": (_ctx.modelValue.bestproxywebaccess ?? 0),
                            "new-value": (_ctx.license.bestproxywebaccess ?? 0)
                          }, null, 8, ["old-value", "new-value"])
                        ]),
                        _: 1
                      }, 8, ["label"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.preview.bestzero())
            ? (_openBlock(), _createBlock(_component_bp_fieldset, {
                key: 2,
                "intense-border": "",
                collapsible: ""
              }, {
                legend: _withCtx(() => _cache[27] || (_cache[27] = [
                  _createTextVNode("bestzero")
                ])),
                default: _withCtx(() => [
                  ((_ctx.modelValue.numberOfLicences ?? 0) !== (_ctx.license.numberOfLicences ?? 0))
                    ? (_openBlock(), _createBlock(_component_bp_label, {
                        key: 0,
                        label: _ctx.$gettext('Connections'),
                        "label-width": "38.2%"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_bp_arrow_calculation, {
                            "old-value": (_ctx.modelValue.numberOfLicences ?? 0),
                            "new-value": (_ctx.license.numberOfLicences ?? 0)
                          }, null, 8, ["old-value", "new-value"])
                        ]),
                        _: 1
                      }, 8, ["label"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}