
import { cssColor } from '@/utils/color';
import { computed, CSSProperties, defineComponent } from 'vue';
import useCompactMode from '@/compositions/use-compact-mode';

export default defineComponent({
  name: 'bp-arrow-calculation',
  props: {
    oldValue: Number,
    difference: Number,
    newValue: Number,
  },
  setup(props) {
    ///-------------------------------------------------------------------
    /// CALCULATED VALUES
    ///-------------------------------------------------------------------

    const oldCalculated = computed(() => props.oldValue ?? ((props.newValue || 0) - (props.difference || 0)))
    const newCalcualted = computed(() => props.newValue ?? ((props.oldValue || 0) + (props.difference || 0)))
    const differenceCalculated = computed(() => props.difference ?? ((props.newValue || 0) - (props.oldValue || 0)))

    ///-------------------------------------------------------------------
    /// DIFFERENCE ICON
    ///-------------------------------------------------------------------

    const icon = computed(() => {
      if (differenceCalculated.value > 0) {
        return {
          icon: 'plus',
          transform: 'shrink-4',
        }
      } else if (differenceCalculated.value < 0) {
        return {
          icon: 'minus',
          transform: 'shrink-4',
        }
      }
      return {
        icon: 'plus-minus',
        transform: 'shrink-4',
      }
    })

    ///-------------------------------------------------------------------
    /// APPEARANCE
    ///-------------------------------------------------------------------

    const { current: compactMode } = useCompactMode();

    ///-------------------------------------------------------------------
    /// STYLE
    ///-------------------------------------------------------------------

    const style = computed<CSSProperties>(() => ({
      '--arrow-calculation-difference-color': differenceCalculated.value > 0 ? cssColor('green') : differenceCalculated.value < 0 ? cssColor('red') : 'var(--theme-text)',
    }))

    ///-------------------------------------------------------------------
    /// RETURN
    ///-------------------------------------------------------------------

    return {
      compactMode,
      differenceCalculated,
      icon,
      newCalcualted,
      oldCalculated,
      style,
    }
  }
});
