
import { App, useAppStore } from '@/stores/app';
import { Breakpoint, useMedia } from '@/utils/responsive-breakpoints';
import { computed, defineComponent, PropType } from 'vue';
import { Customer, useCustomerStore } from '@/stores/customer';
import { useEditionStore } from '@/stores/edition';
import clone from '@sahnee/clone';

export default defineComponent({
  name: 'bp-license-extension-editor-app-list',
  props: {
    apps: {
      type: Object as PropType<App[]>,
      required: true
    },
    customer: {
      type: Object as PropType<Customer>,
      required: true
    },
    license: {
      type: Object as PropType<Customer>,
      required: true
    },
    depth: {
      type: Number,
      default: 0
    }
  },
  emits: ['update:license'],
  setup(props, ctx) {
    ///-------------------------------------------------------------------
    /// STORE
    ///-------------------------------------------------------------------

    const customerStore = useCustomerStore();
    const editionStore = useEditionStore(true);
    const appStore = useAppStore();

    ///-------------------------------------------------------------------
    /// APPS
    ///-------------------------------------------------------------------
    
    // APPS
    const editionApps = (basis: Customer) => (editionStore.getById(customerStore.getBestinformedEdition(basis))?.apps || []).filter(app => !['starter', 'standard', 'alarm'].includes(app));
    const customerApps = (basis: Customer) => customerStore.getBestinformedApps(basis).filter(app => !['starter', 'standard', 'alarm'].includes(app));

    // EXCLUDED APPS
    const editionExcludedApps = (basis: Customer) => (editionStore.getById(customerStore.getBestinformedEdition(basis))?.excludedApps || []).filter(app => !['starter', 'standard', 'alarm'].includes(app));
    const customerExcludedApps = (basis: Customer) => customerStore.getBestinformedExcludedApps(basis).filter(app => !['starter', 'standard', 'alarm'].includes(app));

    // DEPENDENT APPS
    const dependentApps = computed<Map<App['_id'], App[]>>(() => new Map(appStore.getAll().map(app => [app._id, appStore.getAll().filter(a => a.dependencies.includes(app._id))])));

    // HELPER FUNCTIONS
    const isAppActive = (basis: Customer, appId: string) => [...editionApps(basis), ...customerApps(basis)].includes(appId);
    const isAppIncluded = (basis: Customer, appId: string) => editionApps(basis).includes(appId);
    const isAppExcluded = (basis: Customer, appId: string) => [...editionExcludedApps(basis), ...customerExcludedApps(basis)].includes(appId);

    // EVENT HANDLERS
    function changeApp(app: App) {
      const license = clone(props.license);

      const appIndex = license.apps.findIndex((appId: string) => appId === app._id);
      // If the app is not in the license, add it
      if (appIndex === -1) {
        license.apps.push(app._id);
      }
      // Otherwise remove it and all it's (recursively) dependent apps
      else {
        license.apps.splice(appIndex, 1);

        const allDependentApps = (appId: string): App[] => dependentApps.value.get(appId)?.flatMap(app => [app, ...allDependentApps(app._id)]) || [];
        for (const dependentApp of allDependentApps(app._id)) {
          const dependentAppIndex = license.apps.findIndex((appId: string) => appId === dependentApp._id);
          if (dependentAppIndex !== -1) {
            license.apps.splice(dependentAppIndex, 1);
          }
        }

        // If theh removed app is the mobile app we only need to remove the mobile cals
        if (app._id === 'mobile') {
          license.mobilecals = 0;
        }
      }
      
      ctx.emit('update:license', license);
    }

    ///-------------------------------------------------------------------
    /// ARROW
    ///-------------------------------------------------------------------

    const isDesktop = useMedia(Breakpoint.MIN_MD);
    const arrow = computed(() => isDesktop.value ? 'arrow-right-long' : 'arrow-down');

    return {
      arrow,
      changeApp,
      dependentApps,
      isAppActive,
      isAppExcluded,
      isAppIncluded,
      isDesktop,
    }
  }
})

