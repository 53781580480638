import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { class: "bp-license-extension-editor-app-list__wrapper" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bp_icon = _resolveComponent("bp-icon")!
  const _component_bp_input = _resolveComponent("bp-input")!

  return (_openBlock(), _createElementBlock("ul", {
    class: "bp-license-extension-editor-app-list",
    style: _normalizeStyle({ 'margin-top': _ctx.depth ? 'var(--padding-sm)' : undefined })
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.apps.filter(a => _ctx.depth || !a.dependencies.length), (app) => {
      return (_openBlock(), _createElementBlock("li", {
        key: app._id,
        style: _normalizeStyle({ 'margin-bottom': _ctx.depth ? '0' : undefined })
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", {
            class: "flex gap-sm",
            style: _normalizeStyle({ 'margin-left': `calc(${_ctx.depth} * var(--padding-lg))`, 'margin-right': `calc(-${_ctx.depth} * var(--padding-lg))` })
          }, [
            (_ctx.isAppActive(_ctx.customer, app._id))
              ? (_openBlock(), _createBlock(_component_bp_icon, {
                  key: 0,
                  icon: "check",
                  color: "green"
                }))
              : (_openBlock(), _createBlock(_component_bp_icon, {
                  key: 1,
                  icon: "xmark",
                  color: "red"
                })),
            _createElementVNode("span", {
              class: _normalizeClass(['bp-license-extension-editor-app-list__app', {
            'bp-license-extension-editor-app-list__app--active': _ctx.isAppActive(_ctx.customer, app._id),
            'bp-license-extension-editor-app-list__app--included': _ctx.isAppIncluded(_ctx.customer, app._id),
            'bp-license-extension-editor-app-list__app--excluded': _ctx.isAppExcluded(_ctx.customer, app._id),
          }])
            }, _toDisplayString(app.name), 3)
          ], 4),
          _createVNode(_component_bp_icon, { icon: _ctx.arrow }, null, 8, ["icon"]),
          _createVNode(_component_bp_input, {
            disabled: _ctx.isAppIncluded(_ctx.license, app._id) || _ctx.isAppExcluded(_ctx.license, app._id) || !app.dependencies.every(dep => _ctx.isAppActive(_ctx.license, dep)),
            "model-value": _ctx.isAppActive(_ctx.license, app._id),
            style: _normalizeStyle({ 'margin-left': `calc(${_ctx.depth} * var(--padding-lg))`, 'margin-right': `calc(-${_ctx.depth} * var(--padding-lg))` }),
            text: app.name,
            "onUpdate:modelValue": ($event: any) => (_ctx.changeApp(app)),
            flush: "",
            seamless: "",
            type: "checkbox"
          }, null, 8, ["disabled", "model-value", "style", "text", "onUpdate:modelValue"]),
          (!_ctx.isDesktop && !_ctx.dependentApps.get(app._id)?.length)
            ? (_openBlock(), _createElementBlock("hr", _hoisted_2))
            : _createCommentVNode("", true)
        ]),
        (!!_ctx.dependentApps.get(app._id)?.length)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent('bp-license-extension-editor-app-list'), {
              key: 0,
              apps: _ctx.dependentApps.get(app._id),
              customer: _ctx.customer,
              license: _ctx.license,
              depth: _ctx.depth + 1,
              "onUpdate:license": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:license', $event)))
            }, null, 40, ["apps", "customer", "license", "depth"]))
          : _createCommentVNode("", true)
      ], 4))
    }), 128))
  ], 4))
}