
import BpLicenseExtensionEditor from '@/components/license/BpLicenseExtensionEditor.vue';
import { useCustomerStore } from '@/stores/customer';
import { useUser } from '@/utils/user';
import { defineComponent, ref, watch } from 'vue';


export default defineComponent({
  name: 'license-extend-view',
  components: {
    BpLicenseExtensionEditor: BpLicenseExtensionEditor
  },
  setup() {
    ///-------------------------------------------------------------------
    /// CUSTOMER
    ///-------------------------------------------------------------------

    const user = useUser();
    const customer = ref();

    ///-------------------------------------------------------------------
    /// STORE
    ///-------------------------------------------------------------------

    const customerStore = useCustomerStore();

    watch(() => user.value.company, async () => {
      const response = await customerStore.readById(user.value.company);
      if (response && response.success) {
        customer.value = response.data;
      }
    }, { immediate: true })

    return {
      customer,
    }
  }
})

