import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_translate = _resolveComponent("translate")!
  const _component_bp_view_header = _resolveComponent("bp-view-header")!
  const _component_bp_license_extension_editor = _resolveComponent("bp-license-extension-editor")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_bp_view_header, {
      class: "bp-license-extend-view__header",
      image: "/static/background/app_background_license.jpg",
      icon: ['fad', 'file-certificate']
    }, {
      default: _withCtx(() => [
        _createVNode(_component_translate, null, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("License extend")
          ])),
          _: 1
        })
      ]),
      _: 1
    }),
    (_ctx.customer)
      ? (_openBlock(), _createBlock(_component_bp_license_extension_editor, {
          key: 0,
          "model-value": _ctx.customer
        }, null, 8, ["model-value"]))
      : _createCommentVNode("", true)
  ], 64))
}