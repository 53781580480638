import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bp-arrow-calculation__old-value" }
const _hoisted_2 = { class: "bp-arrow-calculation__arrow" }
const _hoisted_3 = { class: "bp-arrow-calculation__new-value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bp_icon = _resolveComponent("bp-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['bp-arrow-calculation', { 'bp-arrow-calculation--compact': _ctx.compactMode }])
  }, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.oldCalculated), 1),
    _createElementVNode("span", {
      class: "bp-arrow-calculation__difference",
      style: _normalizeStyle(_ctx.style)
    }, [
      _createVNode(_component_bp_icon, { icon: _ctx.icon }, null, 8, ["icon"]),
      _createElementVNode("span", null, _toDisplayString(Math.abs(_ctx.differenceCalculated)), 1)
    ], 4),
    _createElementVNode("span", _hoisted_2, [
      _createVNode(_component_bp_icon, { icon: { icon: ['fad', 'arrow-right'], secondaryOpacity: 0, swapOpacity: true } }),
      _createVNode(_component_bp_icon, { icon: { icon: ['fad', 'arrow-right'], secondaryOpacity: 0 } })
    ]),
    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.newCalcualted), 1)
  ], 2))
}